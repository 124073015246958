<div class="cart_section" style="margin-top: -60px;">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="cart_container">

                    <h3>Realize seu cadastro :)
                        <a class="btn btn-info" [routerLink]="['/login']">Já sou cadastrado</a>
                    </h3>
                    <form [formGroup]="clientForm" (submit)="salvarCliente()">
                        <div class="row">

                            <div class="col-lg-8 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Nome *</span>
                                            <input formControlName="nome" class="form-control" autofocus type="text">
                                            <p *ngIf="clientForm.controls['nome'].invalid && clientForm.controls['nome'].errors.required"
                                                class="text-danger alert2">*Informe o nome</p>

                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Sobre nome *</span>
                                            <input formControlName="sobre_nome" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['sobre_nome'].invalid && clientForm.controls['sobre_nome'].errors.required"
                                                class="text-danger alert2">*Informe o sobre nome</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Telefone *</span>

                                            <input mask="00 00000-0000" formControlName="telefone" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['telefone'].invalid && clientForm.controls['telefone'].errors.required"
                                                class="text-danger alert2">*Informe o telefone</p>
                                            <p *ngIf="clientForm.controls['telefone'].invalid && !clientForm.controls['telefone'].errors.required"
                                                class="text-danger alert2">*Minimo de 11 caracteres</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Email *</span>
                                            <input formControlName="email" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['email'].invalid && clientForm.controls['email'].errors.required"
                                                class="text-danger alert2">*Informe o email</p>
                                            <p *ngIf="clientForm.controls['email'].invalid && clientForm.controls['email'].errors.email"
                                                class="text-danger alert2">Email inválido</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-sm-4">
                                        <div class="checkout__input">
                                            <span>Senha *</span>
                                            <input formControlName="senha" class="form-control" name="senha"
                                                type="password">
                                            <p *ngIf="clientForm.controls['senha'].invalid && clientForm.controls['senha'].errors.required"
                                                class="text-danger alert2">*Informe a senha</p>
                                            <p *ngIf="clientForm.controls['senha'].invalid && !clientForm.controls['senha'].errors.required"
                                                class="text-danger alert2">*Minimo de 4 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-4 col-sm-4">
                                        <div class="checkout__input">
                                            <span>Tipo Doc. *</span>
                                            <select formControlName="tp_doc" id="tp_doc" (change)="selectDoc()"
                                                class="form-control">
                                                <option value="cpf">CPF</option>
                                                <option value="cnpj">CNPJ</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="tpDoc == 'cpf'">
                                        <div class="checkout__input">
                                            <span>CPF *</span>
                                            <input formControlName="cpf" mask="000.000.000-00" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cpf'].invalid && clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Informe o CPF</p>
                                            <p *ngIf="clientForm.controls['cpf'].invalid && !clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Minimo de 11 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="clientForm.value.tp_doc == 'cnpj'">
                                        <div class="checkout__input">
                                            <span>CNPJ *</span>
                                            <input formControlName="cpf" mask="00.000.000/0000-00" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cpf'].invalid && clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Informe o CNPJ</p>
                                            <p *ngIf="clientForm.controls['cpf'].invalid && !clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Minimo de 14 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="clientForm.value.tp_doc == 'cnpj'">
                                        <div class="checkout__input">
                                            <span>IE *</span>
                                            <input formControlName="ie" class="form-control" type="text">

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-9 col-9 col-sm-9">
                                        <div class="checkout__input">
                                            <span>Rua *</span>
                                            <input formControlName="rua" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['rua'].invalid && clientForm.controls['rua'].errors.required"
                                                class="text-danger alert2">*Informe o rua</p>
                                            <p *ngIf="clientForm.controls['rua'].invalid && !clientForm.controls['rua'].errors.required"
                                                class="text-danger alert2">*Minimo de 5 caracteres</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-3 col-sm-3">
                                        <div class="checkout__input">
                                            <span>Nº *</span>
                                            <input formControlName="numero" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['numero'].invalid && clientForm.controls['numero'].errors.required"
                                                class="text-danger alert2">*Informe o numero</p>
                                            <p *ngIf="clientForm.controls['numero'].invalid && !clientForm.controls['numero'].errors.required"
                                                class="text-danger alert2">*Minimo de 1 caracter</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-6">
                                        <div class="checkout__input">
                                            <span>Bairro *</span>
                                            <input formControlName="bairro" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['bairro'].invalid && clientForm.controls['bairro'].errors.required"
                                                class="text-danger alert2">*Informe o bairro</p>
                                            <p *ngIf="clientForm.controls['bairro'].invalid && !clientForm.controls['bairro'].errors.required"
                                                class="text-danger alert2">*Minimo de 3 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-6">
                                        <div class="checkout__input">
                                            <span>CEP *</span>
                                            <input formControlName="cep" mask="00000-000" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cep'].invalid && clientForm.controls['cep'].errors.required"
                                                class="text-danger alert2">*Informe o CEP</p>
                                            <p *ngIf="clientForm.controls['cep'].invalid && !clientForm.controls['cep'].errors.required"
                                                class="text-danger alert2">*Minimo de 8 caracteres</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-lg-6 col-8">
                                        <div class="checkout__input">
                                            <span>Cidade *</span>
                                            <input formControlName="cidade" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['cidade'].invalid && clientForm.controls['cidade'].errors.required"
                                                class="text-danger alert2">*Informe o cidade</p>
                                            <p *ngIf="clientForm.controls['cidade'].invalid && !clientForm.controls['cidade'].errors.required"
                                                class="text-danger alert2">*Minimo de 2 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-4">
                                        <div class="checkout__input">
                                            <span>UF *</span>
                                            <input formControlName="uf" mask="AA" class="form-control" data-mask="AA"
                                                data-mask-reverse="true" type="text">
                                            <p *ngIf="clientForm.controls['uf'].invalid && clientForm.controls['uf'].errors.required"
                                                class="text-danger alert2">*Informe a UF</p>
                                            <p *ngIf="clientForm.controls['uf'].invalid && !clientForm.controls['uf'].errors.required"
                                                class="text-danger alert2">*Minimo de 2 caracteres</p>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <span>Complemento </span>
                                            <input formControlName="complemento" class="form-control" type="text">
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-lg-7">
                                    <div class="checkout__input">
                                        <input formControlName="check" class="form-control-check" type="checkbox"
                                            required>
                                        <span> Eu aceito os <a data-toggle="modal" data-target="#myModal"
                                                href="assets/termo/TERMOS E CONDIÇÕES DE USO DO SISTEMA DE MULTIATENDIMENTO.pdf"
                                                target="_blank"> termos e condições da tnet
                                                sistemas</a></span>
                                        <p *ngIf="clientForm.controls['check'].invalid && clientForm.controls['check'].errors.required"
                                            class="text-danger alert2">*Aceite o termos</p>


                                    </div>
                                </div>
                                <!-- Modal -->
                                <div class="modal fade" id="myModal" tabindex="-1" role="dialog"
                                    aria-labelledby="myModalLabel">
                                    <div style=" align-items: center;" class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button style="color:red" type="button" class="close"
                                                    data-dismiss="modal" aria-label="Close"><span
                                                        aria-hidden="true">&times;</span></button>
                                                <h4 class="modal-title text-center" id="myModalLabel">Termo de
                                                    Uso</h4>
                                            </div>
                                            <div class="modal-body">
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p><b>TERMOS E CONDI&#199;&#213;ES DE
                                                                USO DO SISTEMA DE
                                                                MULTIATENDIMENTO TNET <br /></b></p>
                                                        <p><b></b></p>
                                                        <p> <br />A PESSOA F&#205;SICA OU A PESSOA JUR&#205;DICA que deu
                                                            o aceite eletr&#244;nico, leu e <br />est&#225; de acordo
                                                            com o presente &#8220;Termos e Condi&#231;&#245;es de Uso do
                                                            Sistema de <br />Multiatendimento Tnet&#8221;, doravante
                                                            denominada simplesmente &quot;CONTRATANTE&quot;. <br />
                                                            <br /><b>CL&#193;USULA I - ACEITA&#199;&#195;O DO CONTRATO E
                                                                DOCUMENTOS INTEGRANTES <br /></b> <br />Faz parte
                                                            integrante do presente contrato, para todos os fins, o
                                                            formul&#225;rio referido <br />na Cl&#225;usula IV abaixo,
                                                            fornecido pela TNET DESENVOLVIMENTO DE SISTEMAS <br />LTDA
                                                            por meio da Internet no site www.tnetsistemas.com.br, e
                                                            preenchidos pela <br />CONTRATANTE neste site. <br />
                                                            <br />Ao marcar a op&#231;&#227;o &quot;Li e estou de acordo
                                                            com os Termos e Condi&#231;&#245;es de Uso do <br />Sistema
                                                            de Multiatendimento Tnet&#8221; constante do formul&#225;rio
                                                            fornecido pela TNET <br />DESENVOLVIMENTO DE SISTEMAS LTDA
                                                            por meio da Internet, a <br />CONTRATANTE est&#225;
                                                            declarando ter lido e aceito, sem quaisquer reservas, todas
                                                            <br />as cl&#225;usulas e condi&#231;&#245;es do presente
                                                            contrato. <br /> <br /><b>CL&#193;USULA II &#8211;
                                                                DEFINI&#199;&#213;ES <br /></b> <br />SISTEMA DE
                                                            MULTIATENDIMENTO: Programa de computador de propriedade da
                                                            <br />TNET DESENVOLVIMENTO DE SISTEMAS LTDA, criado para
                                                            gerenciar v&#225;rios <br />usu&#225;rios em um &#250;nico
                                                            n&#250;mero de WhatsApp proporcionando m&#250;ltiplos
                                                            <br />atendimentos; <br /> <br />PLATAFORMA ANDROID: Tablet
                                                            ou aparelho celular com sistema operacional <br />Android,
                                                            vers&#227;o 4.2 (Jelly Bean) ou superior, de propriedade da
                                                            CONTRATANTE, <br />onde ser&#225; instalado o programa de
                                                            MULTIATENDIMENTO; <br /> <br />PLATAFORMA WINDONS: Sistema
                                                            operacional de multitarefas para computadores <br />e
                                                            dispositivos m&#243;veis, desenvolvido pela Microsoft;
                                                            <br /> <br />CERTIFICADO DIGITAL (MODELO A1): Cerificado
                                                            Digital &#233; uma assinatura com <br />validade
                                                            jur&#237;dica que garante prote&#231;&#227;o &#224;s
                                                            transa&#231;&#245;es eletr&#244;nicas e outros servi&#231;os
                                                            <br />via internet, permitindo que pessoas e empresas se
                                                            identifiquem e assinem <br />digitalmente de qualquer lugar
                                                            do mundo com mais seguran&#231;a e agilidade; <br />
                                                            <br />MARKET PLACE: Local onde o programa SISTEMA DE
                                                            MULTIATENDIMENTO <br />TNET ser&#225; disponibilizado,
                                                            indicado no site www.tnetsistemas.com.br. <br /> <br />
                                                            <br /><b>CL&#193;USULA III &#8211; OBJETO <br /></b>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>O presente contrato tem por objeto a autoriza&#231;&#227;o de
                                                            uso n&#227;o exclusivo e <br />intransfer&#237;vel, pela
                                                            CONTRATANTE, do programa SISTEMA DE <br />MULTIATENDIMENTO
                                                            TNET acima qualificado. <br /> <br /><b>CL&#193;USULA IV -
                                                                USO DO SERVI&#199;O <br /></b> <br />Para utilizar o
                                                            servi&#231;o como Usu&#225;rio Cadastrado, a CONTRATANTE
                                                            dever&#225; <br />preencher corretamente o formul&#225;rio
                                                            de cadastramento disponibilizado no site
                                                            <br />www.tnetsistemas.com.br, informando na tela de
                                                            contrata&#231;&#227;o: <br /> <br />a) Se pessoa
                                                            f&#237;sica: Nome, RG, CPF, E-mail, Telefone e
                                                            Endere&#231;o; <br />b) Se pessoa jur&#237;dica: Raz&#227;o
                                                            Social, CNPJ, Inscri&#231;&#227;o Estadual, E-mail,
                                                            Telefone, <br />Endere&#231;o e Nome do s&#243;cio
                                                            representante da Empresa. <br /> <br />A CONTRATANTE
                                                            poder&#225; iniciar a utiliza&#231;&#227;o do SISTEMA DE
                                                            <br />MULTIATENDIMENTO TNET somente ap&#243;s o
                                                            cadastramento da senha definitiva <br />ou
                                                            altera&#231;&#227;o da senha provis&#243;ria fornecida no
                                                            site www.tnetsistemas.com.br. <br /> <br />A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA se responsabiliza em
                                                            proteger <br />os dados e senhas fornecidas, que
                                                            estar&#227;o armazenadas em um site que possui,
                                                            <br />medidas de seguran&#231;a, a fim de prevenir acesso,
                                                            uso e divulga&#231;&#227;o n&#227;o autorizada. <br />
                                                            <br />A CONTRATANTE se responsabiliza, civil e
                                                            criminalmente, pela veracidade das
                                                            <br />informa&#231;&#245;es cadastradas. <br />
                                                            <br />Ressaltamos que a utiliza&#231;&#227;o do SISTEMA DE
                                                            MULTIATENDIMENTO TNET est&#225; <br />vinculada ao pagamento
                                                            da taxa mensal vigente. <br /> <br /><b>CL&#193;USULA V
                                                                &#8211; RESPONSABILIDADE E OBRIGA&#199;&#213;ES DA TNET
                                                                <br />DESENVOLVIMENTO DE SISTEMAS LTDA <br /></b>
                                                            <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA se obriga a
                                                            disponibilizar &#224; <br />CONTRATANTE, no Market Place o
                                                            programa SISTEMA DE <br />MULTIATENDIMENTO TNET em sua
                                                            &#250;ltima vers&#227;o, atualizado em rela&#231;&#227;o
                                                            &#224; <br />legisla&#231;&#227;o fiscal, bem como corrigir
                                                            no menor tempo poss&#237;vel eventuais falhas no
                                                            <br />software. <br /> <br />Desse modo, &#233; dever da
                                                            CONTRATANTE manter o programa SISTEMA DE
                                                            <br />MULTIATENDIMENTO TNET sempre atualizado, ficando a
                                                            TNET <br />DESENVOLVIMENTO DE SISTEMAS LTDA isenta de
                                                            qualquer responsabilidade <br />que decorra da falta de
                                                            atualiza&#231;&#227;o do programa. <br /> <br />A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA prestar&#225; &#224;
                                                            CONTRATANTE, <br />servi&#231;o de suporte e atendimento,
                                                            conforme condi&#231;&#245;es explicitadas na Clausula X
                                                            <br />abaixo. <br />
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>No caso de cancelamento ou rescis&#227;o da presente
                                                            contrata&#231;&#227;o, o hist&#243;rico de
                                                            <br />utiliza&#231;&#227;o do SISTEMA DE MULTIATENDIMENTO
                                                            TNET ficar&#225; dispon&#237;vel para
                                                            <br />transfer&#234;ncia dos servidores da TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA <br />para o seu computador
                                                            (download) pelo per&#237;odo de 60 (sessenta) dias a contar
                                                            da <br />data do cancelamento ou rescis&#227;o, ap&#243;s o
                                                            qual ser&#227;o apagadas. <br /> <br /><b>CL&#193;USULA VI
                                                                &#8211; RESPONSABILIDADE E OBRIGA&#199;&#213;ES DA
                                                                CONTRATANTE <br /></b> <br />A CONTRATANTE obriga-se a
                                                            tomar todas as precau&#231;&#245;es necess&#225;rias para
                                                            que <br />terceiros n&#227;o utilizem o servi&#231;o em seu
                                                            nome. <br /> <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                            tem o direito de suspender <br />ou cancelar o acesso da
                                                            CONTRATANTE ao programa SISTEMA DE <br />MULTIATENDIMENTO
                                                            TNET nos casos de fraude, participa&#231;&#227;o em
                                                            obten&#231;&#227;o de <br />benef&#237;cio ou vantagem de
                                                            forma il&#237;cita ou pelo n&#227;o cumprimento de quaisquer
                                                            das <br />condi&#231;&#245;es destes Termos e
                                                            Condi&#231;&#245;es de Uso. Nos casos de suspens&#227;o ou
                                                            <br />cancelamento por estes motivos, a TNET DESENVOLVIMENTO
                                                            DE SISTEMAS <br />LTDA n&#227;o dever&#225; qualquer
                                                            indeniza&#231;&#227;o &#224; CONTRATANTE e poder&#225;
                                                            ingressar com <br />a a&#231;&#227;o cab&#237;vel a fim de
                                                            resguardar seus interesses. <br /> <br />&#201; de
                                                            responsabilidade da CONTRATANTE: <br /> <br />a) Possuir os
                                                            equipamentos de hardware necess&#225;rios para a adequada
                                                            utiliza&#231;&#227;o <br />do SISTEMA DE MULTIATENDIMENTO,
                                                            quais sejam, tablets, smartphones, <br />microcomputadores
                                                            ou outros hardwares compat&#237;veis; <br /> <br />b) Manter
                                                            seu equipamento e demais dispositivos de acesso ao software
                                                            SISTEMA <br />DE MULTIATENDIMENTO TNET seguros, valendo-se
                                                            de ferramentas espec&#237;ficas <br />para tanto, tais como
                                                            antiv&#237;rus, firewall, entre outras, de modo a contribuir
                                                            para a <br />preven&#231;&#227;o de riscos eletr&#244;nicos;
                                                            <br /> <br />c) Utilizar sistemas operacionais e navegadores
                                                            atualizados e na vers&#227;o definida de <br />acordo com as
                                                            especifica&#231;&#245;es fornecidas; <br /> <br />d)
                                                            Equipar-se e responsabilizar-se pelos dispositivos de
                                                            hardware necess&#225;rios para <br />o acesso ao SISTEMA DE
                                                            MULTIATENDIMENTO, bem como pelo acesso desses
                                                            <br />dispositivos &#224; Internet; <br />e) O uso, a
                                                            guarda, a manuten&#231;&#227;o e o sigilo de todas as senhas
                                                            e logins para acesso <br />ao SISTEMA DE MULTIATENDIMENTO, e
                                                            garantindo &#224; TNET <br />DESENVOLVIMENTO DE SISTEMAS
                                                            LTDA que n&#227;o compartilhar&#225; as senhas com
                                                            <br />quaisquer terceiros, exceto seus empregados
                                                            autorizados, que as utilizar&#227;o <br />somente em seu
                                                            benef&#237;cio. <br /> <br />f) A CONTRATANTE dever&#225;
                                                            manter o programa SISTEMA DE <br />MULTIATENDIMENTO TNET
                                                            sempre atualizado com a &#250;ltima vers&#227;o, ficando a
                                                            <br />TNET DESENVOLVIMENTO DE SISTEMAS LTDA isenta de
                                                            qualquer problema <br />decorrente da falta de
                                                            atualiza&#231;&#227;o do programa.
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p> <br /><b>CL&#193;USULA VII &#8211; DECLARA&#199;&#195;O DE
                                                                PRIVACIDADE <br /></b> <br /><b>Informa&#231;&#245;es
                                                                gerais: <br /></b> <br />A finalidade desta
                                                            cl&#225;usula &#233; explicar como a TNET DESENVOLVIMENTO DE
                                                            <br />SISTEMAS LTDA poder&#225; coletar, guardar, processar,
                                                            compartilhar e transferir os <br />dados pessoais da
                                                            CONTRATANTE quando esta utilizar os servi&#231;os oferecidos
                                                            <br />(&#8220;Declara&#231;&#227;o de Privacidade&#8221;).
                                                            <br /> <br /><b>Quais dados pessoais a TNET DESENVOLVIMENTO
                                                                DE SISTEMAS LTDA <br />coleta? <br /></b> <br />A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA coleta
                                                            informa&#231;&#245;es sobre a <br />CONTRATANTE quando usar
                                                            os servi&#231;os prestados, incluindo o seguinte: <br />
                                                            <br />
                                                        </p>
                                                        <p>a) Informa&#231;&#245;es de registro e uso. Quando a
                                                            CONTRATANTE se registra para <br />usar os servi&#231;os da
                                                            TNET DESENVOLVIMENTO DE SISTEMAS LTDA <br />criando uma
                                                            conta, s&#227;o coletados dados pessoais, nos termos do
                                                            artigo 5&#186; <br />da Lei n&#186; 13.709/2018, conforme
                                                            necess&#225;rio para oferecer e cumprir os
                                                            <br />servi&#231;os contratados. <br />
                                                        </p>
                                                        <p> <br />b) Informa&#231;&#245;es de transa&#231;&#227;o e
                                                            experi&#234;ncia. Quando a CONTRATANTE utiliza <br /></p>
                                                        <p>os servi&#231;os da TNET DESENVOLVIMENTO DE SISTEMAS LTDA,
                                                            por <br />exemplo, para realizar vendas e receber
                                                            pagamentos, s&#227;o coletadas <br />informa&#231;&#245;es
                                                            sobre a transa&#231;&#227;o, bem como outras
                                                            informa&#231;&#245;es associadas &#224;
                                                            <br />transa&#231;&#227;o, como o valor envolvido, dados de
                                                            uso t&#233;cnico e localiza&#231;&#227;o
                                                            <br />geogr&#225;fica. <br />
                                                        </p>
                                                        <p> <br />c) Informa&#231;&#245;es do participante. Quando a
                                                            CONTRATANTE utiliza os servi&#231;os <br /></p>
                                                        <p>prestados pela TNET DESENVOLVIMENTO DE SISTEMAS LTDA,
                                                            s&#227;o <br />coletados os dados pessoais que s&#227;o
                                                            fornecidos sobre os outros <br />participantes associados
                                                            &#224; transa&#231;&#227;o. <br /></p>
                                                        <p> <br />d) Informa&#231;&#245;es que a CONTRATANTE optar por
                                                            fornecer para obter servi&#231;os <br /></p>
                                                        <p>adicionais ou servi&#231;os espec&#237;ficos online. Caso a
                                                            CONTRATANTE deseje <br />solicitar servi&#231;os ou
                                                            funcionalidades opcionais avan&#231;adas, poder&#227;o ser
                                                            <br />coletadas mais informa&#231;&#245;es. A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS <br />LTDA enviar&#225; &#224;
                                                            CONTRATANTE um aviso separado no momento da coleta, <br />se
                                                            o uso dessas informa&#231;&#245;es forem diferentes do uso
                                                            divulgado nesta <br />Declara&#231;&#227;o de Privacidade.
                                                            <br />
                                                        </p>
                                                        <p> <br />e) Informa&#231;&#245;es de fontes terceirizadas sobre
                                                            a CONTRATANDA. A TNET <br /></p>
                                                        <p>DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; obter
                                                            informa&#231;&#245;es de <br />fontes terceirizadas, como
                                                            provedores de dados e ag&#234;ncias de cr&#233;dito,
                                                            <br />quando permitido por lei. <br />
                                                        </p>
                                                        <p> </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>f) Outras informa&#231;&#245;es que ser&#227;o coletadas em
                                                            rela&#231;&#227;o ao uso dos servi&#231;os. A <br />TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; coletar
                                                            <br />informa&#231;&#245;es adicionais de e sobre a
                                                            CONTRATANTE quando est&#225; se <br />comunicar pela TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA pelo do <br />Servi&#231;o
                                                            e Atendimento &#224; CONTRATANTE ou ao responder alguma
                                                            pesquisa. <br />
                                                        </p>
                                                        <p> <br /><b>Por que a TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                                guardar&#225; os dados <br />pessoais? <br /></b>
                                                            <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA guardar&#225;
                                                            os dados pessoais <br />para cumprir as obriga&#231;&#245;es
                                                            legais ou regulamentares e para suas finalidades de
                                                            <br />neg&#243;cios. Poder&#227;o ser guardados dados
                                                            pessoais por per&#237;odos mais longos que <br />exigido por
                                                            lei, caso seja pelo leg&#237;timo interesse da TNET
                                                            DESENVOLVIMENTO <br />DE SISTEMAS LTDA e n&#227;o proibido
                                                            por lei. Se a conta da CONTRATANTE for <br />encerrada, a
                                                            TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; adotar
                                                            <br />medidas para mascarar dados pessoais e outras
                                                            informa&#231;&#245;es, mas se reservar&#225; a
                                                            <br />capacidade de guardar e acessar os dados conforme
                                                            obrigat&#243;rio para cumprir as <br />leis vigentes. A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA continuar&#225; a
                                                            <br />usar e divulgar esses dados pessoais de acordo com
                                                            essa Declara&#231;&#227;o de <br />Privacidade. <br />
                                                            <br /><b>Como a TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                                processar&#225; os dados <br />pessoais? <br /></b>
                                                            <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225;
                                                            processar as <br />informa&#231;&#245;es da CONTRATANTE
                                                            pelos seguintes motivos: <br /> <br />
                                                        </p>
                                                        <p>a) Para prestar os Servi&#231;os, incluindo para: autenticar
                                                            o acesso da <br />CONTRATANTE a uma conta; se comunicar com
                                                            a CONTRATANTE sobre <br />sua conta e os servi&#231;os
                                                            contratados; criar uma conex&#227;o entre a conta da
                                                            <br />CONTRATANTE e uma conta ou plataforma de terceiros;
                                                            realizar <br />verifica&#231;&#245;es de cr&#233;dito e
                                                            outras verifica&#231;&#245;es de reputa&#231;&#227;o
                                                            financeira, avaliar <br />aplicativos e comparar
                                                            informa&#231;&#245;es para fins de confirma&#231;&#227;o e
                                                            precis&#227;o; e <br />manter a conta da CONTRATANTE e
                                                            informa&#231;&#245;es financeiras atualizadas. <br />
                                                        </p>
                                                        <p> <br />b) Para gerenciar as necessidades de neg&#243;cios da
                                                            TNET <br /></p>
                                                        <p>DESENVOLVIMENTO DE SISTEMAS LTDA, como monitorar, analisar e
                                                            <br />melhorar o desempenho e a funcionalidade dos
                                                            servi&#231;os. Por exemplo, ser&#225; <br />analisado o
                                                            comportamento da CONTRATANTE e conduzidas pesquisas
                                                            <br />sobre o modo como a CONTRATANTE utiliza os
                                                            servi&#231;os contratados. <br />
                                                        </p>
                                                        <p> <br />c) Para gerenciar riscos e contra fraudes, ser&#227;o
                                                            realizadas a verifica&#231;&#227;o da <br /></p>
                                                        <p>identidade da CONTRATANTE. As ferramentas de riscos e fraudes
                                                            da TNET <br />DESENVOLVIMENTO DE SISTEMAS LTDA usam os dados
                                                            pessoais, as <br />informa&#231;&#245;es do dispositivo,
                                                            dados de uso t&#233;cnico e informa&#231;&#245;es de
                                                            <br />localiza&#231;&#227;o geogr&#225;fica para ajudar a
                                                            detectar e prevenir fraudes e abuso dos <br />servi&#231;os.
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p> <br />d) Para cumprir as obriga&#231;&#245;es e fazer
                                                            cumprir os termos dos servi&#231;os da TNET <br /></p>
                                                        <p>DESENVOLVIMENTO DE SISTEMAS LTDA, incluindo para cumprir
                                                            todas as <br />leis e regulamentos aplic&#225;veis. <br />
                                                        </p>
                                                        <p> <br /><b>A TNET DESENVOLVIMENTO DE SISTEMAS LTDA compartilha
                                                                dados <br />dessoais? <br /></b> <br />A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; compartilhar os
                                                            dados <br />pessoais ou outras informa&#231;&#245;es da
                                                            CONTRATANTE com outras pessoas de <br />algumas formas,
                                                            conforme descrito nesta se&#231;&#227;o da
                                                            Declara&#231;&#227;o de Privacidade e <br />pelos seguintes
                                                            motivos: <br /> <br /></p>
                                                        <p>a) Com outras empresas do grupo econ&#244;mico da TNET
                                                            DESENVOLVIMENTO <br />DE SISTEMAS LTDA. A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA <br />poder&#225;
                                                            compartilhar os dados pessoais da CONTRATANTE com empresas
                                                            <br />do grupo econ&#244;mico da TNET DESENVOLVIMENTO DE
                                                            SISTEMAS LTDA <br />para, entre outras coisas, prestar os
                                                            servi&#231;os solicitados ou autorizados; para
                                                            <br />gerenciar riscos; para ajudar a detectar e impedir
                                                            atos potencialmente ilegais <br />e fraudulentos e outras
                                                            viola&#231;&#245;es de pol&#237;ticas e contratos; e para
                                                            auxiliar no <br />gerenciamento da disponibilidade e
                                                            conectividade dos produtos, servi&#231;os e
                                                            <br />comunica&#231;&#245;es da TNET DESENVOLVIMENTO DE
                                                            SISTEMAS LTDA. <br />
                                                        </p>
                                                        <p> <br />b) Com outras empresas que fornecem servi&#231;os para
                                                            a TNET <br /></p>
                                                        <p>DESENVOLVIMENTO DE SISTEMAS LTDA. A TNET DESENVOLVIMENTO
                                                            <br />DE SISTEMAS LTDA poder&#225; compartilhar os dados
                                                            pessoais com <br />prestadores de servi&#231;os
                                                            terceirizados que realizam servi&#231;os e fun&#231;&#245;es
                                                            sob <br />orienta&#231;&#227;o e nome da TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA. <br />Esses prestadores de
                                                            servi&#231;os terceirizados podem, por exemplo, prestar
                                                            <br />servi&#231;os, verificar identidade da CONTRATANTE,
                                                            ajudar no processamento <br />de transa&#231;&#245;es,
                                                            enviar an&#250;ncios dos produtos e servi&#231;os ou prestar
                                                            <br />atendimento ao cliente da TNET DESENVOLVIMENTO DE
                                                            SISTEMAS <br />LTDA. <br />
                                                        </p>
                                                        <p> <br />c) Com institui&#231;&#245;es financeiras,
                                                            institui&#231;&#245;es de arranjos de pagamento e <br /></p>
                                                        <p>assemelhadas: podemos compartilhar Dados Pessoais com
                                                            institui&#231;&#245;es <br />financeiras,
                                                            institui&#231;&#245;es de arranjos de pagamento e
                                                            assemelhadas com <br />quem temos parceria para criar ou
                                                            oferecer produtos conjuntamente. Essas
                                                            <br />institui&#231;&#245;es s&#243; poder&#227;o usar essas
                                                            informa&#231;&#245;es para comercializar e oferecer
                                                            <br />produtos relacionados aos neg&#243;cios da TNET
                                                            DESENVOLVIMENTO DE <br />SISTEMAS LTDA, a menos que
                                                            voc&#234; permita outros tipos de uso. Tamb&#233;m
                                                            <br />podemos compartilhar Dados Pessoais para processar
                                                            transa&#231;&#245;es, fornecer <br />benef&#237;cios
                                                            associados aos seus servi&#231;os e manter suas
                                                            informa&#231;&#245;es <br />financeiras atualizadas. <br />
                                                        </p>
                                                        <p> <br />d) Com outros terceiros para fins de neg&#243;cios ou
                                                            conforme permitido ou exigido <br /></p>
                                                        <p>por lei. A TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225;
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>compartilhar suas informa&#231;&#245;es com terceiros para
                                                            fins de neg&#243;cios da TNET <br />DESENVOLVIMENTO DE
                                                            SISTEMAS LTDA ou conforme permitido ou <br />exigido por
                                                            lei, incluindo: se for preciso fazer isso para estar em
                                                            conformidade <br />com a lei, processos legais ou normas;
                                                            autoridades da lei ou outros <br />funcion&#225;rios do
                                                            governo, ou terceiros, para seguir uma intima&#231;&#227;o,
                                                            ordem <br />judicial ou outros processos legais ou
                                                            exig&#234;ncias aplic&#225;veis &#224; TNET
                                                            <br />DESENVOLVIMENTO DE SISTEMAS LTDA; se a TNET
                                                            <br />DESENVOLVIMENTO DE SISTEMAS LTDA entender, a seu
                                                            exclusivo <br />crit&#233;rio, que a divulga&#231;&#227;o de
                                                            dados pessoais &#233; necess&#225;ria ou apropriada para
                                                            <br />evitar danos f&#237;sicos ou perdas financeiras ou
                                                            decorrente de uma investiga&#231;&#227;o <br />de atividade
                                                            ilegal suspeita ou real; para proteger os interesses vitais
                                                            de uma <br />pessoa; para investigar viola&#231;&#245;es ou
                                                            executar um Contrato do Usu&#225;rio ou <br />outros Termos
                                                            de Uso pertinentes a qualquer servi&#231;o; para proteger
                                                            nossos <br />direitos de propriedade, servi&#231;os e
                                                            legais; para facilitar uma compra ou venda <br />de
                                                            neg&#243;cios da TNET DESENVOLVIMENTO DE SISTEMAS LTDA no
                                                            todo <br />ou em parte; para ajudar a avaliar e gerenciar
                                                            riscos e evitar fraudes contra <br />a TNET DESENVOLVIMENTO
                                                            DE SISTEMAS LTDA, seus Usu&#225;rios e fraude <br />envolva
                                                            o site ou o uso dos servi&#231;os, incluindo fraude que
                                                            ocorra ou envolva <br />eventuais parceiros de
                                                            neg&#243;cios, empreendimentos estrat&#233;gicos ou outros
                                                            <br />indiv&#237;duos; para empresas com as quais a TNET
                                                            DESENVOLVIMENTO DE <br />SISTEMAS LTDA pretende se fundir ou
                                                            adquirir; e para apoiar os <br />departamentos de auditoria,
                                                            conformidade e governan&#231;a corporativa da <br />TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA. <br />
                                                        </p>
                                                        <p> <br />e) Com o seu consentimento. A TNET DESENVOLVIMENTO DE
                                                            SISTEMAS <br /></p>
                                                        <p>LTDA tamb&#233;m compartilha os dados pessoais da CONTRATANTE
                                                            e outras <br />informa&#231;&#245;es desde que com o seu
                                                            consentimento ou orienta&#231;&#227;o. <br /></p>
                                                        <p> <br />f) Al&#233;m disso, a TNET DESENVOLVIMENTO DE SISTEMAS
                                                            LTDA poder&#225; <br /></p>
                                                        <p>fornecer dados estat&#237;sticos agregados a terceiros,
                                                            incluindo outras empresas <br />e pessoas, sobre como,
                                                            quando e por que os usu&#225;rios visitam nosso site e
                                                            <br />usam nossos servi&#231;os. Esses dados n&#227;o
                                                            s&#227;o pessoalmente identific&#225;veis <br />nem fornecem
                                                            informa&#231;&#245;es sobre seu uso dos servi&#231;os ou
                                                            sites. <br />
                                                        </p>
                                                        <p> <br /><b>Como a TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                                protege seus Dados <br />Pessoais? <br /></b> <br />A
                                                            TNET DESENVOLVIMENTO DE SISTEMAS LTDA mant&#233;m medidas
                                                            f&#237;sicas, <br />t&#233;cnicas e administrativas de
                                                            seguran&#231;a, projetadas para fornecer prote&#231;&#227;o
                                                            <br />razo&#225;vel para os dados pessoais da CONTRATANTE
                                                            contra perda, mau uso, <br />acesso n&#227;o autorizado,
                                                            divulga&#231;&#227;o e altera&#231;&#227;o. Embora a TNET
                                                            <br />DESENVOLVIMENTO DE SISTEMAS LTDA se dedique a proteger
                                                            seus sistemas <br />e servi&#231;os, a CONTRATANTE &#233;
                                                            respons&#225;vel por proteger e manter a privacidade
                                                            <br />de suas senhas e informa&#231;&#245;es de registro de
                                                            conta ou perfil e por verificar se os <br />dados pessoais
                                                            que foram informados &#224; TNET DESENVOLVIMENTO DE
                                                            <br />SISTEMAS LTDA s&#227;o precisos e atuais. <br />
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p><b>Altera&#231;&#245;es nesta Declara&#231;&#227;o de
                                                                Privacidade: <br /></b> <br /></p>
                                                        <p>a) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; a
                                                            revisar esta <br />Declara&#231;&#227;o de Privacidade
                                                            periodicamente para refletir altera&#231;&#245;es em seus
                                                            <br />neg&#243;cios, site, servi&#231;os ou leis vigentes. A
                                                            Declara&#231;&#227;o de Privacidade <br />revisada
                                                            entrar&#225; em vigor a partir da data efetiva de sua
                                                            publica&#231;&#227;o no site <br />da TNET DESENVOLVIMENTO
                                                            DE SISTEMAS LTDA. <br />
                                                        </p>
                                                        <p> <br />b) Se a vers&#227;o revisada incluir uma
                                                            altera&#231;&#227;o substancial, a CONTRATANTE <br /></p>
                                                        <p>ser&#225; informada com 30 dias de anteced&#234;ncia, por
                                                            meio da divulga&#231;&#227;o de <br />aviso acerca da
                                                            altera&#231;&#227;o no site da TNET DESENVOLVIMENTO DE
                                                            <br />SISTEMAS LTDA no seguinte link:
                                                            https://tnetsistemas.com.br/termos-de-<br />uso/. A TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225; notificar a
                                                            <br />CONTRATANTE sobre a altera&#231;&#227;o atrav&#233;s
                                                            de e-mail ou outros meios. <br />
                                                        </p>
                                                        <p> <br /><b>CL&#193;USULA VIII - ISEN&#199;&#195;O DE
                                                                RESPONSABILIDADE DA TNET <br />DESENVOLVIMENTO DE
                                                                SISTEMAS LTDA <br /></b> <br /></p>
                                                        <p>a) Tendo em vista a impossibilidade de funcionamento integral
                                                            e ininterrupto de <br />qualquer sistema de
                                                            telecomunica&#231;&#227;o ou de inform&#225;tica, durante
                                                            365 <br />(trezentos e sessenta e cinco) dias por ano, 24
                                                            (vinte e quatro) horas por dia, <br />nesta
                                                            situa&#231;&#227;o de fragilidade tamb&#233;m se incluindo,
                                                            em raz&#227;o de sua <br />complexidade, a
                                                            disponibiliza&#231;&#227;o do servi&#231;o, inclusive em
                                                            raz&#227;o da <br />depend&#234;ncia de servi&#231;os de
                                                            telecomunica&#231;&#245;es prestados por terceiros, a
                                                            <br />TNET DESENVOLVIMENTO DE SISTEMAS LTDA n&#227;o
                                                            garante, de <br />nenhuma forma, a presta&#231;&#227;o do
                                                            servi&#231;o de forma ininterrupta ou isenta de <br />erros
                                                            e n&#227;o se responsabiliza pela impossibilidade de se
                                                            realizarem <br />transa&#231;&#245;es ou
                                                            movimenta&#231;&#245;es durante os per&#237;odos de
                                                            indisponibilidade do <br />servi&#231;o, sendo que nesse
                                                            caso, a CONTRATANTE n&#227;o poder&#225; exigir o
                                                            <br />pagamento por lucro cessante e nem qualquer outro tipo
                                                            de indeniza&#231;&#227;o. <br />
                                                        </p>
                                                        <p> <br />b) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA n&#227;o se
                                                            responsabiliza, <br /></p>
                                                        <p>expressa ou implicitamente, pela performance das vendas da
                                                            <br />CONTRATANTE, pelo relacionamento da CONTRATANTE com os
                                                            seus <br />clientes, por despesas diretas e indiretas tidas
                                                            pela CONTRATANTE para <br />possibilitar o funcionamento dos
                                                            servi&#231;os prestados pela TNET <br />DESENVOLVIMENTO DE
                                                            SISTEMAS LTDA ou, ainda, pelo <br />descumprimento de
                                                            disposi&#231;&#245;es legais e regulat&#243;rias por parte
                                                            da <br />CONTRATANTE que sejam relativas &#224;s atividades
                                                            por ela exercidas. A TNET <br />DESENVOLVIMENTO DE SISTEMAS
                                                            LTDA envidar&#225; seus esfor&#231;os para <br />notificar a
                                                            CONTRATANTE com anteced&#234;ncia a respeito de quaisquer
                                                            <br />opera&#231;&#245;es planejadas de manuten&#231;&#227;o
                                                            ou reparo que possam resultar na <br />suspens&#227;o dos
                                                            servi&#231;os. <br />
                                                        </p>
                                                        <p> <br />c) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA n&#227;o se
                                                            responsabiliza <br /></p>
                                                        <p>pela conectividade com a internet exigida para funcionamento
                                                            do SISTEMA </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>DE MULTI ATENDIMENTO, bem como pela indisponibilidade,
                                                            tempor&#225;ria ou <br />n&#227;o, dos sistemas
                                                            governamentais de emiss&#227;o das notas fiscais
                                                            eletr&#244;nicas. <br /></p>
                                                        <p> <br />d) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA n&#227;o se
                                                            responsabiliza <br /></p>
                                                        <p>por qualquer dano, preju&#237;zo ou perda ocasionada por
                                                            falhas no equipamento <br />utilizado pela CONTRATANTE, seja
                                                            decorrente de condutas de terceiros, <br />caso fortuito,
                                                            for&#231;a maior ou infec&#231;&#227;o de v&#237;rus,
                                                            decorrente de acesso ou <br />navega&#231;&#227;o na
                                                            internet ou como consequ&#234;ncia da transfer&#234;ncia de
                                                            dados de <br />qualquer natureza. <br /></p>
                                                        <p> <br />e) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA n&#227;o se
                                                            responsabiliza, <br /></p>
                                                        <p>expressa ou implicitamente, pela adequa&#231;&#227;o do
                                                            software SISTEMA DE <br />MULTIATENDIMENTO TNET aos
                                                            prop&#243;sitos espec&#237;ficos da <br />CONTRATANTE,
                                                            devendo esta, em caso de qualquer d&#250;vida a respeito,
                                                            <br />entrar em contato com o suporte do SISTEMA DE
                                                            MULTIATENDIMENTO, <br />pelos canais colocados &#224;
                                                            disposi&#231;&#227;o na Clausula X abaixo. <br />
                                                        </p>
                                                        <p> <br /><b>CL&#193;USULA IX &#8211; CONFIDENCIALIDADE
                                                                <br /></b> <br />Observado o disposto neste documento,
                                                            em especial na Declara&#231;&#227;o de Privacidade
                                                            <br />acima, a TNET DESENVOLVIMENTO DE SISTEMAS LTDA se
                                                            obriga a manter <br />sigilo e confidencialidade sobre
                                                            qualquer informa&#231;&#227;o a qual vier a ter acesso em
                                                            <br />virtude dos servi&#231;os ora contratados, devendo
                                                            manter este sigilo pelo per&#237;odo de 5 <br />(cinco) anos
                                                            ap&#243;s o t&#233;rmino da contrata&#231;&#227;o. <br />
                                                            <br /><b>CL&#193;USULA X - SUPORTE E ATENDIMENTO &#192;
                                                                CONTRATANTE <br /></b> <br />O suporte t&#233;cnico e
                                                            operacional ao Servi&#231;o ser&#225; realizado diretamente
                                                            pela TNET <br />DESENVOLVIMENTO DE SISTEMAS LTDA ou por
                                                            terceiro por ela contratado, sob <br />sua responsabilidade.
                                                            O suporte por via telef&#244;nica ser&#225; prestado
                                                            atrav&#233;s dos <br />n&#250;meros de telefone constantes
                                                            no site www.tnetsistemas.com.br, sem direito a
                                                            <br />utiliza&#231;&#227;o de sistema gratuito de telefonia,
                                                            devendo a CONTRATANTE assumir <br />integralmente os custos
                                                            das liga&#231;&#245;es locais e interurbanas. O servi&#231;o
                                                            de suporte <br />prestado via e-mail
                                                            tnettecnologia@gmail.com ou por qualquer outro meio
                                                            eletr&#244;nico <br />disponibilizado pela TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA ser&#227;o <br />gratuitos.
                                                            <br /> <br />Em caso de problemas ou dificuldade de
                                                            utiliza&#231;&#227;o do SISTEMA DE <br />MULTIATENDIMENTO, o
                                                            CONTRATANTE dever&#225; entrar em contato com o suporte
                                                            <br />do SISTEMA DE MULTIATENDIMENTO TNET por via
                                                            telef&#244;nica ou pelo e-mail
                                                            <br />tnettecnologia@gmail.com. A partir da
                                                            formaliza&#231;&#227;o desta comunica&#231;&#227;o, a TNET
                                                            <br />DESENVOLVIMENTO DE SISTEMAS LTDA trabalhar&#225; para
                                                            solucionar o problema <br />no menor prazo poss&#237;vel.
                                                            <br /> <br /><b>CL&#193;USULA XI - PAGAMENTO PELOS
                                                                SERVI&#199;OS DO SISTEMA DE <br />MULTIATENDIMENTO
                                                                <br /></b>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>a) Os servi&#231;os disponibilizados pelo programa SISTEMA DE
                                                            <br />MULTIATENDIMENTO TNET s&#227;o do tipo PR&#201;-PAGO,
                                                            P&#211;S PAGO e <br />RECORRENTE, configurando, assim, uma
                                                            rela&#231;&#227;o de trato sucessivo, a qual <br />se renova
                                                            mensalmente mediante o pagamento da respectiva mensalidade.
                                                            <br />Para fins de clareza, a utiliza&#231;&#227;o dos
                                                            servi&#231;os do programa SISTEMA DE <br />MULTIATENDIMENTO
                                                            TNET depender&#225; do pr&#233;vio pagamento da
                                                            <br />mensalidade correspondente aos servi&#231;os
                                                            contratados. <br />
                                                        </p>
                                                        <p> <br />b) A CONTRATANTE autoriza expressamente a TNET
                                                            DESENVOLVIMENTO <br /></p>
                                                        <p>DE SISTEMAS LTDA, a cobrar a mensalidade devida pela
                                                            presta&#231;&#227;o dos <br />servi&#231;os do SISTEMA DE
                                                            MULTIATENDIMENTO, no valor e na forma <br />escolhida pela
                                                            CONTRATANTE no site www.tnetsistemas.com.br no <br />momento
                                                            da contrata&#231;&#227;o. <br /></p>
                                                        <p> <br />c) Quando assinado o plano anual o cliente tem
                                                            at&#233; 07 dias para solicitar o <br /></p>
                                                        <p>cancelamento e ter o valor ressarcido. Ap&#243;s 07 dias, o
                                                            cliente ter&#225; a fidelidade <br />de 12 meses ou mais de
                                                            acordo com a regra promocional assinada. <br /></p>
                                                        <p> <br />d) O n&#227;o recebimento do boleto ou outra forma de
                                                            aviso de d&#233;bito at&#233; a data de <br /></p>
                                                        <p>seu vencimento, n&#227;o &#233; justificativa para o n&#227;o
                                                            pagamento, devendo a <br />CONTRATANTE, nesta hip&#243;tese,
                                                            entrar imediatamente em contato com a <br />TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA, sob pena de suspens&#227;o
                                                            <br />do servi&#231;o do SISTEMA DE MULTIATENDIMENTO, sendo
                                                            que neste caso, <br />n&#227;o ser&#225; devida nenhuma
                                                            indeniza&#231;&#227;o ao CONTRATANTE. <br />
                                                        </p>
                                                        <p> <br />e) Em caso do n&#227;o pagamento da mensalidade, o
                                                            servi&#231;o do programa <br /></p>
                                                        <p>SISTEMA DE MULTIATENDIMENTO TNET ser&#225; suspenso at&#233;
                                                            que o <br />pagamento seja regularizado. <br /></p>
                                                        <p> <br />f) Sem preju&#237;zo do disposto acima, na
                                                            hip&#243;tese em que a CONTRATANTE se <br /></p>
                                                        <p>tornar inadimplente em rela&#231;&#227;o &#224;s
                                                            obriga&#231;&#245;es pecuni&#225;rias previstas nestes
                                                            <br />Termos e Condi&#231;&#245;es de Uso, a TNET
                                                            DESENVOLVIMENTO DE SISTEMAS <br />LTDA poder&#225; se
                                                            utilizar de qualquer medida judicial ou extrajudicial para
                                                            <br />receber o pagamento devido, com o acr&#233;scimo de
                                                            atualiza&#231;&#227;o monet&#225;ria e <br />dos juros
                                                            legais, al&#233;m de multa n&#227;o compensat&#243;ria de 2%
                                                            (dois por cento) <br />sobre o total do d&#233;bito, estando
                                                            a TNET DESENVOLVIMENTO DE <br />SISTEMAS LTDA autorizada, a
                                                            seu crit&#233;rio, a apontar o nome da contratante <br />aos
                                                            &#243;rg&#227;os de prote&#231;&#227;o ao cr&#233;dito, a
                                                            protestar os t&#237;tulos que sejam emitidos <br />com base
                                                            na presta&#231;&#227;o dos servi&#231;os e a ajuizar as
                                                            a&#231;&#245;es adequadas &#224; <br />satisfa&#231;&#227;o
                                                            de seus cr&#233;ditos, independente das demais
                                                            consequ&#234;ncias <br />contratuais. <br />
                                                        </p>
                                                        <p> <br />g) Os valores referentes &#224; mensalidade ser&#227;o
                                                            reajustados todo dia 1&#186; de janeiro, <br /></p>
                                                        <p>de acordo com o IGP-M ou, na sua aus&#234;ncia, com o
                                                            &#237;ndice que vier a <br />substitu&#237;-lo,
                                                            independentemente de pr&#233;via notifica&#231;&#227;o ao
                                                            usu&#225;rio. <br /></p>
                                                        <p> <br /><b>CAP&#205;TULO XII &#8211; SUSPENS&#195;O DOS
                                                                SERVI&#199;OS </b></p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p> <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA se reserva o
                                                            direito de <br />suspender imediata e indefinidamente a
                                                            presta&#231;&#227;o dos servi&#231;os &#224; CONTRATANTE
                                                            <br />e a bloquear o acesso ao programa SISTEMA DE
                                                            MULTIATENDIMENTO TNET <br />sem aviso pr&#233;vio nos casos
                                                            elencados abaixo <br /> <br />
                                                        </p>
                                                        <p>(i) tenha fornecido informa&#231;&#245;es falsas, imprecisas,
                                                            incompletas ou <br />enganosas; <br /> <br /></p>
                                                        <p>ii) tenha violado os termos do presente Contrato; <br />
                                                            <br />
                                                        </p>
                                                        <p>(iii) imponha &#224; TNET DESENVOLVIMENTO DE SISTEMAS LTDA um
                                                            risco <br />de fraude inaceit&#225;vel; <br /> <br /></p>
                                                        <p>(iv) esteja fazendo uso dos servi&#231;os em
                                                            viola&#231;&#227;o de lei ou regulamento
                                                            <br />aplic&#225;vel; <br /> <br />
                                                        </p>
                                                        <p>(v) esteja envolvido em condutas fraudulentas ou ilegais; ou
                                                            <br /> <br />
                                                        </p>
                                                        <p>(vi) esteja em atraso no pagamento dos servi&#231;os ora
                                                            contratados por per&#237;odo <br />superior a 30 (trinta)
                                                            dias. <br /> <br />A TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                            envidar&#225; seus esfor&#231;os para <br />notificar a
                                                            CONTRATANTE com anteced&#234;ncia a respeito de sua
                                                            inten&#231;&#227;o em <br />suspender os servi&#231;os e
                                                            apresentar&#225; as raz&#245;es para tanto. <br />
                                                            <br /><b>CL&#193;USULA XIII &#8211; PROPRIEDADE INTELECTUAL
                                                                <br /></b> <br />
                                                        </p>
                                                        <p>a) Todos os direitos relativos ao SISTEMA DE MULTIATENDIMENTO
                                                            TNET e <br />suas funcionalidades s&#227;o direito de
                                                            propriedade intelectual exclusiva da <br />TNET
                                                            DESENVOLVIMENTO DE SISTEMAS LTDA, inclusive no que diz
                                                            <br />respeito aos seus textos, imagens, layouts, base de
                                                            dados, c&#243;digos de <br />programa&#231;&#227;o,
                                                            linguagens inform&#225;ticas, bases de dados e demais
                                                            conte&#250;dos <br />produzidos direta ou indiretamente pela
                                                            TNET DESENVOLVIMENTO DE <br />SISTEMAS LTDA, e s&#227;o
                                                            protegidos pelas leis e tratados internacionais de
                                                            <br />direito autoral, marcas, patentes, modelos e desenhos
                                                            industriais, <br />especialmente a lei 9609/98. <br />
                                                        </p>
                                                        <p> <br />b) O uso indevido e a reprodu&#231;&#227;o total ou
                                                            parcial dos referidos conte&#250;dos s&#227;o <br /></p>
                                                        <p>proibidos, salvo quando autorizados expressamente pela TNET
                                                            <br />DESENVOLVIMENTO DE SISTEMAS LTDA. <br />
                                                        </p>
                                                        <p> <br />c) A CONTRATANTE se compromete a n&#227;o ceder,
                                                            sublicenciar, vender, doar, <br /></p>
                                                        <p>alienar, alugar ou transferir, total ou parcialmente, sob
                                                            quaisquer <br />modalidades, a qualquer t&#237;tulo, o
                                                            SISTEMA DE MULTIATENDIMENTO, <br />assim como seus manuais,
                                                            guias, procedimentos ou quaisquer outros <br />documentos a
                                                            eles relacionados. Compromete-se, ainda, a n&#227;o revelar,
                                                            n&#227;o </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>duplicar, n&#227;o copiar, n&#227;o reproduzir, n&#227;o
                                                            autorizar e/ou permitir o uso e/ou <br />acesso do SISTEMA
                                                            DE MULTIATENDIMENTO TNET por terceiros. <br /></p>
                                                        <p> <br />d) A CONTRATANTE se compromete a n&#227;o utilizar o
                                                            m&#233;todo da engenharia <br /></p>
                                                        <p>reversa, desmontagem, descompila&#231;&#227;o ou qual quer
                                                            outra tentava para <br />descobrir o c&#243;digo fonte do
                                                            SISTEMA DE MULTIATENDIMENTO TNET no <br />todo ou em parte.
                                                            <br />
                                                        </p>
                                                        <p> <br />e) Nada nestes Termos de Uso dever&#225; ser
                                                            interpretado como restri&#231;&#227;o a <br /></p>
                                                        <p>qualquer direito da TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                            sobre <br />o software, inclusive, sem limita&#231;&#227;o,
                                                            no que se refere a seu direito de, a todo <br />e qualquer
                                                            tempo, desenvolver, modificar, explorar comercialmente e
                                                            <br />licenciar, a quaisquer terceiros e sob qualquer
                                                            modalidade, o SISTEMA DE <br />MULTIATENDIMENTO. <br />
                                                        </p>
                                                        <p> <br />f) A constata&#231;&#227;o de qualquer
                                                            viola&#231;&#227;o a estas regras de propriedade intelectual
                                                            <br />
                                                        </p>
                                                        <p>sujeitar&#225; o usu&#225;rio &#224;s penalidades previstas
                                                            na Lei. <br /> <br /><b>CL&#193;USULA XIV &#8211; PRAZO,
                                                                ALTERA&#199;&#213;ES E RESCIS&#195;O. <br /></b>
                                                            <br />Este contrato &#233; celebrado por prazo
                                                            indeterminado, entrando e mantendo-se em <br />vigor a
                                                            partir da data de sua aceita&#231;&#227;o pela CONTRATANTE,
                                                            observando as <br />seguintes regras: <br /> <br />
                                                        </p>
                                                        <p>a) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225;
                                                            modificar, <br />adicionar ou remover quaisquer
                                                            cl&#225;usulas ou condi&#231;&#245;es deste contrato.
                                                            <br />Quando a altera&#231;&#227;o implicar
                                                            restri&#231;&#227;o das condi&#231;&#245;es inicialmente
                                                            pactuadas, <br />a TNET DESENVOLVIMENTO DE SISTEMAS LTDA
                                                            informar&#225; &#224; <br />CONTRATANTE da mudan&#231;a, por
                                                            e-mail, via postal ou por qualquer outro <br />meio
                                                            eletr&#244;nico, com pelo menos 30 (trinta) dias de
                                                            anteced&#234;ncia da entrada <br />em vigor da
                                                            altera&#231;&#227;o. <br />
                                                        </p>
                                                        <p>b) A CONTRATANTE poder&#225; rescindir o presente, em
                                                            at&#233; 30 dias, a partir da <br />ades&#227;o a este
                                                            termo. Para tanto, dever&#225; enviar e-mail para
                                                            <br />tnettecnologia@gmail.com, aguardar a
                                                            confirma&#231;&#227;o do recebimento da
                                                            <br />solicita&#231;&#227;o. Caso n&#227;o haja
                                                            manifesta&#231;&#227;o clara da contratante, a fidelidade se
                                                            <br />impor&#225; pelos por 12 meses seguintes ao
                                                            in&#237;cio do contrato. <br />
                                                        </p>
                                                        <p>c) A TNET DESENVOLVIMENTO DE SISTEMAS LTDA poder&#225;
                                                            rescindir o <br />presente contrato a qualquer momento,
                                                            mediante envio de notifica&#231;&#227;o por <br />escrito,
                                                            com anteced&#234;ncia m&#237;nima de 30 dias. <br /></p>
                                                        <p> <br /><b>CL&#193;USULA XV &#8211; DISPOSI&#199;&#213;ES
                                                                GERAIS <br /></b> <br />A toler&#226;ncia de uma parte
                                                            relativamente ao descumprimento de qualquer das
                                                            <br />obriga&#231;&#245;es da outra n&#227;o ser&#225;
                                                            considerada nova&#231;&#227;o ou renuncia a qualquer
                                                            direito, <br />constituindo mera liberalidade, que n&#227;o
                                                            impedir&#225; a parte tolerante de exigir da outra <br />seu
                                                            cumprimento, a qualquer tempo. <br />
                                                        </p>

                                                    </div>
                                                </div>
                                                <div style="page-break-before:always; page-break-after:always">
                                                    <div>
                                                        <p>Na hip&#243;tese de qualquer disposi&#231;&#227;o destes
                                                            Termos e Condi&#231;&#245;es de Uso vir a ser
                                                            <br />declarada nula por qualquer movo, tal
                                                            disposi&#231;&#227;o n&#227;o afetar&#225; &#224;s demais
                                                            <br />disposi&#231;&#245;es, as quais continuar&#227;o em
                                                            vigor produzindo seus efeitos. <br /> <br />CL&#193;USULA
                                                            XVI &#8211; FORO <br /> <br />As partes elegem como foro
                                                            competente o da comarca de V&#225;rzea Grande &#8211; MT,
                                                            <br />para dirimir quaisquer controv&#233;rsias oriundas
                                                            deste contrato, renunciando a <br />qualquer outro, por mais
                                                            privilegiado que seja. <br />
                                                        </p>

                                                    </div>
                                                </div>


                                                <!-- <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger"
                                                        data-dismiss="modal">Fechar</button>
                                                      <button type="button" class="btn btn-primary">Save changes</button>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-lg-12">
                                        <div class="checkout__input">
                                            <span>Observação </span>
                                            <input class="form-control" type="text"
                                                placeholder="Observação sobre entrega por exemplo">
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="cart_container">
                                    <div class="cart_items">

                                        <ul class="cart_list">

                                            <li class="cart_item clearfix">
                                                <div class="cart_title">Seu Pedido</div>

                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 80%;">Produto</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr *ngFor="let p of produtos">
                                                            <td>
                                                                {{p.name}}
                                                                <strong
                                                                    *ngIf="p.type == 'variable'">{{p.variacao.name}}</strong>

                                                            </td>
                                                            <td>
                                                                <div class="cart_item_text" *ngIf="p.type == 'single'">
                                                                    {{ p.valor_ecommerce | currency:'BRL' }}
                                                                </div>
                                                                <div class="cart_item_text"
                                                                    *ngIf="p.type == 'variable'">
                                                                    {{ p.variacao.sell_price_inc_tax | currency:'BRL' }}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr>

                                                <div class="checkout__order__total">Total
                                                    <span>
                                                        <strong>
                                                            {{ total | currency:'BRL' }}
                                                        </strong>
                                                    </span>
                                                </div>

                                                <button [style.background]="config.cor_btn" class="btn"
                                                    style="width: 100%; color: #fff;" type="submit"
                                                    [disabled]="!clientForm.valid">
                                                    Salvar
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>