import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  SERVER_URL = ''

  constructor(private http: HttpClient) {
    this.SERVER_URL = environment.url
  }

  public config():any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/config', { headers: reqHeader });
  }

  public salvarEmail(email) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/config/salvarEmail', {email: email}, { headers: reqHeader });
  }

  public salvarContato(data) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/config/salvarContato', {data: data}, { headers: reqHeader });
  }

}
