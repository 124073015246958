import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { ClienteService } from '../services/cliente.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-area-cliente-body',
  templateUrl: './area-cliente-body.component.html',
  styleUrls: ['./area-cliente-body.component.css']
})
export class AreaClienteBodyComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('openModal') openModal;

  senha = ''
  repitaSenha = ''
  enderecoId = 0;
  tituloModalEndereco = 'Cadastrar endereço'
  @Input() config: any = {}
  cliente: any = {
    pedidos: [],
    enderecos: []
  }

  clientForm: FormGroup = this.formBuilder.group({
    nome: ['', [Validators.required]],
    sobre_nome: ['', [Validators.required]],
    telefone: ['', [Validators.required, Validators.minLength(11)]],
    email: ['', [Validators.email, Validators.required]],
  })

  enderedoForm: FormGroup = this.formBuilder.group({
    rua: ['123', [Validators.required]],
    numero: ['123', [Validators.required]],
    bairro: ['123', [Validators.required]],
    cep: ['84200-000', [Validators.required]],
    cidade: ['123', [Validators.required]],
    uf: ['PR', [Validators.required]],
    complemento: ['', []],
  })

  constructor(
    private clienteService: ClienteService,
    private storageService: LocalStorageService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    this.getCliente()
  }

  createForm() {
    this.clientForm = this.formBuilder.group({
      nome: [this.cliente.nome, [Validators.required]],
      sobre_nome: [this.cliente.sobre_nome, [Validators.required]],
      telefone: [this.cliente.telefone, [Validators.required, Validators.minLength(11)]],
      email: [this.cliente.email, [Validators.email, Validators.required]],
    });
  }

  getCliente() {
    let cliente = this.storageService.getCliente()

    this.clienteService.findWithData(cliente.token)
      .subscribe(
        data => {
          this.cliente = data
          this.createForm()
        },
        err => {
        }
      )
  }

  alterarSenha() {
    if (this.senha != this.repitaSenha) {
      swal("Erro", "Senhas não coencidem!", "error")
    } else if (this.senha.length < 4) {
      swal("Erro", "Informe no mínimo 4 caracteres!", "warning")
    } else {
      let cliente = this.storageService.getCliente()
      this.clienteService.alterarSenha(cliente.token, this.senha)
        .subscribe(
          data => {
            swal("Sucesso", "Senha alterada!!", "success")
              .then(() => { this.closebutton.nativeElement.click(); })
          },
          err => {
            swal("Erro", "Algo deu errado!!", "error")
          }
        )
    }
  }

  logoff() {
    this.storageService.logoffCliente();
    this.router.navigate(['/']);
  }

  atualizarCliente() {
    let cli = this.storageService.getCliente()
    let data = this.clientForm.value
    data.token = cli.token
    this.clienteService.atualizar(data)
      .subscribe(
        d => {
          let js = { nome: data.nome, token: cli.token }
          this.storageService.setCliente(js)
        }, err => {
          swal("Erro", "Algo deu errado!!", "error")
        }
      )
  }

  salvarEndereco() {
    let data = this.enderedoForm.value
    let cliente = this.storageService.getCliente()

    if (this.enderecoId == 0) {
      this.clienteService.salvarEndereco(data, cliente.token)
        .subscribe(
          data => {
            swal("Sucesso", "Endereço cadastrado!!", "success")
              .then(() => {
                this.closebutton2.nativeElement.click();
                this.getCliente()
              })

          },
          err => {
            swal("Erro", "Algo deu errado!!", "error")
          }
        )
    }else{
      this.clienteService.atualizarEndereco(data, this.enderecoId)
        .subscribe(
          data => {
            swal("Sucesso", "Endereço atualizado!!", "success")
              .then(() => {
                this.closebutton2.nativeElement.click();
                this.getCliente()
              })

          },
          err => {
            swal("Erro", "Algo deu errado!!", "error")
          }
        )
    }
  }

  editEndereco(endereco) {
    this.tituloModalEndereco = 'Editar endereço'
    this.enderecoId = endereco.id
    this.openModal.nativeElement.click();

    this.enderedoForm = this.formBuilder.group({
      rua: [endereco.rua, [Validators.required]],
      numero: [endereco.numero, [Validators.required]],
      bairro: [endereco.bairro, [Validators.required]],
      cep: [endereco.cep, [Validators.required]],
      cidade: [endereco.cidade, [Validators.required]],
      uf: [endereco.uf, [Validators.required]],
      complemento: [endereco.complemento, []],
    })
  }

  estados() {
    return [
      "AC",
      "AL",
      "AM",
      "AP",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MG",
      "MS",
      "MT",
      "PA",
      "PB",
      "PE",
      "PI",
      "PR",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SE",
      "SP",
      "TO"
    ];
  }

}
