<div class="container" *ngIf="loadding">
  <app-loader></app-loader>
</div>
<section class="shop_section layout_padding" *ngIf="produtos.length > 0 && !loadding">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        Produtos em Destaque
      </h2>
    </div>
    <div class="row">
      <div class="col-md-6" *ngFor="let p of produtos">
        <div class="box">
          <a [routerLink]="['/produto', p.id]">
            <div class="img-box">
              <img src="{{p.image_url}}" alt="">
            </div>
            <div class="detail-box">
              <h6>
                {{p.name}}
              </h6>
              <h6>
                Valor:
                <span>
                  {{ p.valor_ecommerce | currency:'BRL' }}
                </span>
              </h6>
            </div>
            <div [style.background]="config.cor_fundo" class="new" *ngIf="p.novo">
              <span>
                Novo
              </span>
            </div>
          </a>
        </div>
      </div>

    </div>
    <!-- <div class="btn-box">
      <a href="">
        Ver todos
      </a>
    </div> -->
  </div>
</section>

<section class="about_section layout_padding" *ngIf="produtoRand && !loadding" [style.background]="config.cor_fundo">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-5 ">
        <div class="img-box">
          <img src="{{produtoRand.image_url}}" alt="">
        </div>
      </div>
      <div class="col-md-6 col-lg-7">
        <div class="detail-box">
          <div class="heading_container">
            <h2>
              {{produtoRand.name}}
            </h2>
          </div>
          <div style="height: 120px;" class="text-truncate">
            <div class="product_text" [innerHTML]="produtoRand.product_description | safe: 'html'">
            </div>
        </div>
          
          <a [style.background]="config.cor_btn" style="border: none;" [routerLink]="['/produto', produtoRand.id]">
            Comprar
          </a>
        </div>
      </div>
    </div>
  </div>
</section>