<div class="cart_section" style="margin-top: -40px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="cart_container" *ngIf="pix == false">
                    <div class="cart_title text-center">
                        Formas de Pagamento
                    </div>
                    <div class="cart_title text-center" [style.color]="config.cor_fundo">
                        {{ pedido.valor_total | currency:'BRL' }}

                    </div>
                    <br>
                    <div class="row pays">
                        <div class="col-lg-2"></div>
                        <div (click)="setTipoPagamento('cartao')" class="col-lg-3 col-4 div-cartao text-center"
                            [class.active]="tipo == 'cartao'">
                            Cartão de Crédito
                        </div>
                        <div (click)="setTipoPagamento('pix')" class="col-lg-3 col-4 div-pix text-center"
                            [class.active]="tipo == 'pix'">
                            PIX
                        </div>
                        <div (click)="setTipoPagamento('boleto')" class="col-lg-3 col-4 div-boleto text-center"
                            [class.active]="tipo == 'boleto'">
                            Boleto
                        </div>
                    </div>

                    <div class="row">

                        <!-- cartao inicio -->
                        <div class="col-12 mix cartao" *ngIf="tipo == 'cartao'">
                            <br>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h3>Pagamento com Cartão</h3>
                                </div>
                            </div>
                            <br>

                            <form [formGroup]="cartaoForm" id="paymentForm" (submit)="payCartao()">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-12">

                                        <div class="checkout__input">

                                            <span>Titular do cartão*</span>
                                            <input formControlName="cardholderName" class="form-control"
                                                id="cardholderName" data-checkout="cardholderName" type="text">

                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 col-6">
                                        <div class="checkout__input">
                                            <span>Tipo de documento</span>
                                            <select class="form-control" data-checkout="docType"
                                                formControlName="docType">
                                                <option selected value="CPF">CPF</option>
                                                <option value="CNPJ">CNPJ</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">
                                            <span>Número do documento*</span>
                                            <input class="form-control" id="docNumber" data-checkout="docNumber"
                                                formControlName="docNumber" type="text">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">
                                            <span>Email*</span>
                                            <input class="form-control" id="email" formControlName="email" type="text">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-12">

                                        <div class="checkout__input">

                                            <span>Número do cartão*</span>

                                            <input (keyup)="validacartao()" class="form-control"
                                                data-checkout="cardNumber" id="cardNumber" type="text"
                                                formControlName="cardNumber">
                                            <img id="band-img" style="width: 20px;" src="">

                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-6">
                                        <div class="checkout__input">
                                            <span>Parcelas</span>

                                            <select style="margin-left: 0px;" class="form-control" id="installments"
                                                formControlName="installments">
                                                <option value="{{p.installments}}" *ngFor="let p of parcelas">
                                                    {{p.recommended_message}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Data de Vencimento*</span>

                                            <div class="row">
                                                <div class="col-6">
                                                    <input mask="00" class="form-control" placeholder="MM"
                                                        data-checkout="cardExpirationMonth" id="cardExpirationMonth"
                                                        type="text" formControlName="cardExpirationMonth">

                                                </div>
                                                <div class="col-6">

                                                    <input mask="00" class="form-control" placeholder="AA"
                                                        data-checkout="cardExpirationYear" id="cardExpirationYear"
                                                        type="text" formControlName="cardExpirationYear">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Código de segurança*</span>
                                            <input class="form-control" data-checkout="securityCode" id="securityCode"
                                                type="text" formControlName="securityCode">
                                        </div>
                                    </div>


                                    <div style="visibility: hidden" class="form-group col-lg-2 col-md-8 col-12">
                                        <label class="col-form-label">Banco emissor</label>
                                        <div class="">
                                            <div class="input-group">
                                                <select class="custom-select" id="issuer" name="issuer"
                                                    data-checkout="issuer" formControlName="securityCode">
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <input style="visibility: hidden" type="" formControlName="transactionAmount"
                                        id="transactionAmount" value="{{pedido.valor_total}}" />
                                    <input style="visibility: hidden" formControlName="description">
                                    <input style="visibility: hidden" formControlName="paymentMethodId"
                                        id="paymentMethodId" value="{{paymentMethodId}}" />

                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <button [disabled]="loader == true" [style.background]="config.cor_btn"
                                            type="submit" class="button cart_button_checkout btn-cartao">PAGAR COM
                                            CARTÃO
                                            <div *ngIf="loader" class="spinner-border" role="status">
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <!-- PIX -->

                        <div class="col-12 mix pix" *ngIf="tipo == 'pix'">
                            <br>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h3>Pagamento com PIX</h3>
                                </div>
                            </div>
                            <br>

                            <form [formGroup]="pixForm" (submit)="payPix()">
                                <div class="row">
                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Nome*</span>
                                            <input class="form-control" formControlName="payerFirstName"
                                                id="payerFirstName" type="text">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Sobrenome*</span>
                                            <input class="form-control" formControlName="payerLastName"
                                                id="payerLastName" type="text">
                                        </div>
                                    </div>
                                    <div class="col-lg-4"></div>

                                    <div class="col-lg-2 col-6 col-sm-6">
                                        <div class="checkout__input">

                                            <span>Tipo de documento</span>

                                            <select class="form-control" data-checkout="docType"
                                                formControlName="docType" style="margin-left: 0;">
                                                <option selected value="CPF">CPF</option>
                                                <option value="CNPJ">CNPJ</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-12 col-sm-6">
                                        <div class="checkout__input">

                                            <span>Número do documento*</span>

                                            <input class="form-control" id="docNumber" formControlName="docNumber"
                                                data-checkout="docNumber" name="docNumber" type="text">

                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Email*</span>
                                            <input class="form-control" formControlName="payerEmail" id="payerEmail"
                                                type="email">
                                        </div>
                                    </div>

                                    <input style="visibility: hidden" type="" formControlName="transactionAmount"
                                        id="transactionAmount" value="{{pedido.valor_total}}" />
                                    <input style="visibility: hidden" formControlName="description">
                                    <input style="visibility: hidden" formControlName="paymentMethodId"
                                        id="paymentMethodId" value="{{paymentMethodId}}" />
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <button [style.background]="config.cor_btn" type="submit"
                                            class="button cart_button_checkout btn-pix">

                                            PAGAR COM PIX
                                            <div *ngIf="loader" class="spinner-border" role="status">
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- Boleto -->

                        <div class="col-12 mix boleto" *ngIf="tipo == 'boleto'">
                            <br>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h3>Pagamento com Boleto</h3>
                                </div>
                            </div>
                            <br>

                            <form [formGroup]="boletoForm" (submit)="payBoleto()">
                                <div class="row">
                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">
                                            <span>Nome*</span>
                                            <input class="form-control" formControlName="payerFirstName"
                                                id="payerFirstName" type="text">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12 col-sm-6">

                                        <div class="checkout__input">
                                            <span>Sobrenome*</span>
                                            <input class="form-control" formControlName="payerLastName"
                                                id="payerLastName" type="text">
                                        </div>
                                    </div>
                                    <div class="col-lg-4"></div>

                                    <div class="col-lg-2 col-6 col-sm-6">
                                        <div class="checkout__input">

                                            <span>Tipo de documento*</span>

                                            <select class="form-control" data-checkout="docType"
                                                formControlName="docType" style="margin-left: 0;">
                                                <option selected value="CPF">CPF</option>
                                                <option value="CNPJ">CNPJ</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-12 col-sm-6">

                                        <div class="checkout__input">

                                            <span>Número do documento*</span>

                                            <input class="form-control" id="docNumber" data-checkout="docNumber"
                                                formControlName="docNumber" type="text">
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-12 col-sm-6">

                                        <div class="checkout__input">
                                            <span>Email*</span>
                                            <input formControlName="payerEmail" class="form-control" id="payerEmail"
                                                type="email">
                                        </div>
                                    </div>


                                    <input style="visibility: hidden" type="" formControlName="transactionAmount"
                                        id="transactionAmount" value="{{pedido.valor_total}}" />
                                    <input style="visibility: hidden" formControlName="description">
                                    <input style="visibility: hidden" formControlName="paymentMethodId"
                                        id="paymentMethodId" value="{{paymentMethodId}}" />
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <button type="submit" [style.background]="config.cor_btn"
                                            class="button cart_button_checkout btn-boleto">PAGAR COM
                                            BOLETO
                                            <div *ngIf="loader" class="spinner-border" role="status">
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                    </div>

                </div>

                <div class="cart_container" *ngIf="pix">

                    <h2 class="text-center">
                        Escaneie ou copie a chave para pagar sua compra!
                    </h2>

                    <h3 class="text-center">
                        Permaneça nesta tela para pagamento!
                    </h3>

                    <div class="row">
                        <div class="col-lg-12">
                            <img class="b64" src="data:image/jpeg;base64,{{qrCode64}}" />
                        </div>
                        <div class="col-lg-12">

                            <div class="col-lg-11 offset-lg-1">

                                <div class="input-group">
                                    <input readonly type="text" class="form-control input-lg" value="{{copiaeCola}}"
                                        id="qrcode_input" />

                                    <div class="input-group-append2">
                                        <span class="input-group-text">
                                            <i (click)="copy()" class="fa fa-copy">
                                            </i>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>