import { Component, Input, OnInit } from '@angular/core';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-categorias-destaque',
  templateUrl: './categorias-destaque.component.html',
  styleUrls: ['./categorias-destaque.component.css']
})
export class CategoriasDestaqueComponent implements OnInit {
  categorias:any = []
  @Input() config: any = {}

  constructor(
    private produtoService: ProdutosService
  ) { }

  ngOnInit(): void {
    this.getCategorias()
  }

  getCategorias(){
    this.produtoService.geCategoriasEmDestaque().subscribe(
      data => {
        this.categorias = data
      },
      err => {
      }
    )
  }

}
