import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdutosService } from 'src/app/services/produtos.service';
@Component({
  selector: 'app-produto-categoria-body',
  templateUrl: './produto-categoria-body.component.html',
  styleUrls: ['./produto-categoria-body.component.css']
})
export class ProdutoCategoriaBodyComponent implements OnInit {
  produtos: any = [{}]
  categoria: any = {name: ''}
  @Input() config: any = {}

  constructor(
    private produtoService: ProdutosService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let id = params.get('id');
        this.getProdutosPorCategoria(id)
        this.getCategoria(id)

      });
  }

  getProdutosPorCategoria(id) {
    this.produtoService.getProdutosPorCategoria(id).subscribe(
      data => {
        this.produtos = data
      }, err => {
      }
    )
  }

  getCategoria(id) {
    this.produtoService.getCategoria(id).subscribe(
      data => {
        this.categoria = data
      }, err => {
      }
    )
  }
}
