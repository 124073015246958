<header class="header_section">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg custom_nav-container ">
      <a class="navbar-brand" href="/">

        <span *ngIf="config.logo_url == ''">
          {{config.nome}}
        </span>

        <img width="120" *ngIf="config.logo_url != ''" src="{{config.logo_url}}" alt="">
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class=""> </span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/categorias']"> Categorias </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/novidades']"> Novidades </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/contato']">Contato</a>
          </li>
        </ul>
        <div class="user_option-box">
          <a [routerLink]="['/login']" *ngIf="cliente == null">
            <i class="fa fa-user" aria-hidden="true"></i>
          </a>
          <a [routerLink]="['/area-cliente']" *ngIf="cliente != null">
            <i class="fa fa-user text-success" aria-hidden="true"></i>
          </a>
          <a [routerLink]="['/carrinho']">
            <i class="fa fa-cart-plus" aria-hidden="true"></i>
            <span [style.color]="config.cor_fundo" class="info">
              {{carrinho.length}}
            </span>
          </a>
          <a [routerLink]="['/pesquisa']">
            <i class="fa fa-search" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </nav>
  </div>
</header>