<section class="shop_section layout_padding">
    <div class="container">
        <div class="heading_container heading_center">
            <h2>
                Produtos categoria: <strong>{{categoria.name}}</strong>
            </h2>
        </div>
        <div class="row">
            <div *ngFor="let p of produtos; let first = first;" [ngClass]="first ? 'col-md-6' : 'col-md-6 col-xl-3'">
                <div class="box" [routerLink]="['/produto', p.id]">
                    <a [routerLink]="['/produto', p.id]">
                        <div class="img-box">
                            <img src="{{p.image_url}}" alt="">
                        </div>
                        <div class="detail-box">
                            <h6>
                                {{p.name}}
                            </h6>
                            <h6>
                                Valor:
                                <span>
                                    {{ p.valor_ecommerce | currency:'BRL' }}
                                </span>
                            </h6>
                        </div>
                        <div [style.background]="config.cor_fundo" class="new" *ngIf="p.novo">
                            <span>
                                Novo
                            </span>
                        </div>
                    </a>
                </div>
            </div>

            <h3 *ngIf="produtos.length == 0" class="text-danger">Categoria sem produtos cadastrados :(</h3>

        </div>

    </div>
</section>