import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CarrinhoService } from '../services/carrinho.service';
import { ClienteService } from '../services/cliente.service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-endereco-body',
  templateUrl: './endereco-body.component.html',
  styleUrls: ['./endereco-body.component.css']
})
export class EnderecoBodyComponent implements OnInit {
  @Input() config: any = {}
  @ViewChild('closebutton2') closebutton2;

  cliente: any = {
    nome: '',
    enderecos: []
  }
  produtos = []
  total = 0
  frete = null
  valorFrete = 0
  tipo = ''
  tipoFrete = null

  loadding = true
  cupom_desconto = ""
  valor_desconto = 0
  descontoStr = ""
  enderedoForm: FormGroup = this.formBuilder.group({
    rua: ['123', [Validators.required]],
    numero: ['123', [Validators.required]],
    bairro: ['123', [Validators.required]],
    cep: ['84200-000', [Validators.required]],
    cidade: ['123', [Validators.required]],
    uf: ['PR', [Validators.required]],
    complemento: ['', []],
  })

  constructor(
    private storageService: LocalStorageService,
    private clienteService: ClienteService,
    private carrinhoService: CarrinhoService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.tipoFrete = params.get('tipoFrete');
      });
    this.getCliente()
    this.montarCarrinho()
  }

  getCliente() {
    let cliente = this.storageService.getCliente()
    let carrinho = this.storageService.getCarrinho()

    carrinho = JSON.stringify(carrinho)
    this.clienteService.findWithCart(cliente.token, carrinho)
      .subscribe(
        data => {
          console.log(data)
          this.cliente = data

          if (this.cliente.enderecos.length > 0 && this.tipoFrete != 'c') {
            this.selectFrete(this.cliente.enderecos[0], this.tipoFrete)
          }
          this.loadding = false

        },
        err => {
          console.log(err)
        }
      )
  }

  montarCarrinho() {
    let cart = this.storageService.getCarrinho()

    this.getItens(cart)
  }

  getItens(cart) {
    this.carrinhoService.itens(JSON.stringify(cart)).subscribe(
      data => {
        this.produtos = data
        this.addQuantidade(cart)
      },
      err => {
      }
    )
  }

  addQuantidade(cart) {
    for (let i = 0; i < this.produtos.length; i++) {
      cart.map((c) => {
        if (c.id == this.produtos[i].id) {
          this.produtos[i].quantidade = c.quantidade
          this.produtos[i].variacao_id = c.variacao_id

          if (this.produtos[i].type == 'single') {
            this.total += this.produtos[i].quantidade * this.produtos[i].valor_ecommerce
          } else {
            this.total += this.produtos[i].quantidade * this.produtos[i].variacao.sell_price_inc_tax
          }
        }
      })
    }
  }


  selectFrete(freteAux, tipo) {
    this.tipo = tipo
    this.frete = freteAux

    if (tipo == 'pac') {
      this.valorFrete = parseFloat(freteAux.preco.replace(",", "."))
    } else if (tipo == 'sedex') {
      this.valorFrete = parseFloat(freteAux.preco_sedex.replace(",", "."))
    } else {
      this.valorFrete = 0
    }
  }

  keyUpCupom() {
    console.log(this.cupom_desconto)
    if (this.cupom_desconto.length == 6) {
      let cliente = this.storageService.getCliente()

      let js = {
        total: this.total + this.valorFrete,
        cupom: this.cupom_desconto,
        token_cliente: cliente.token
      }
      console.log(js)
      this.carrinhoService.calculaDesconto(js)
        .subscribe(
          data => {
            this.valor_desconto = data
            this.descontoStr = "Valor do desconto R$ " + formatCurrency(data, "pt-br", "")
          },
          err => {
            console.log(err)
            if (err.status == 404) {
              swal("Erro", "Cupom não encontrado!", "error")
            }
            else if (err.status == 403) {
              swal("Erro", "Valor desconto maior que total da compra!", "error")
            } else if (err.status == 402) {
              swal("Erro", "Cliente não encontrado!", "error")
            } else if (err.status == 405) {
              swal("Erro", "Cupom já utilizado!", "error")
            } else {
              swal("Erro", "Erro ao gerar desconto!", "error")
            }
            this.valor_desconto = 0
            this.descontoStr = ""
            this.cupom_desconto = ""
          }
        )
    } else {
      this.valor_desconto = 0
      this.descontoStr = ""
      // this.cupom_desconto = ""

    }
  }

  salvarPedido() {
    let js = {
      carrinho: this.produtos,
      cliente: this.cliente,
      endereco: this.frete,
      total: this.total,
      valor_frete: this.valorFrete,
      tipo_frete: this.tipo,
      cupom_desconto: this.cupom_desconto,
      valor_desconto: this.valor_desconto
    }
    this.carrinhoService.salvarPedido(js)
      .subscribe(
        data => {
          this.router.navigate(['/pagamento', data]);
        },
        err => {
          console.log(err)
          swal("Erro", "Erro ao gerar pedido!", "error")
        }
      )
  }

  estados() {
    return [
      "AC",
      "AL",
      "AM",
      "AP",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MG",
      "MS",
      "MT",
      "PA",
      "PB",
      "PE",
      "PI",
      "PR",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SE",
      "SP",
      "TO"
    ];
  }

  salvarEndereco() {
    let data = this.enderedoForm.value
    let cliente = this.storageService.getCliente()

    this.clienteService.salvarEndereco(data, cliente.token)
      .subscribe(
        data => {
          swal("Sucesso", "Endereço cadastrado!!", "success")
            .then(() => {
              this.closebutton2.nativeElement.click();
              this.getCliente()
            })

        },
        err => {
          swal("Erro", "Algo deu errado!!", "error")
        }
      )
  }

}
