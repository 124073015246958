import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarrinhoService } from '../services/carrinho.service';

@Component({
  selector: 'app-pedido-finalizado-body',
  templateUrl: './pedido-finalizado-body.component.html',
  styleUrls: ['./pedido-finalizado-body.component.css']
})
export class PedidoFinalizadoBodyComponent implements OnInit {

  @Input() config: any = {}
  pedido: any = {
    total: 0,
    endereco: {},
    mensagem_agradecimento: ''
  }
  loadding = true

  constructor(
    private route: ActivatedRoute,
    private carrinhoService: CarrinhoService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let token = params.get('token');
        this.getPedido(token)
      });
  }

  getPedido(token) {
    this.carrinhoService.getPedido(token).
      subscribe(
        data => {
          console.log(data)
          this.pedido = data
          if (this.pedido.link_boleto != "") {
            // window.open(this.pedido.link_boleto, '_blank');
            this.loadding = false
          }
        },
        err => {
        }
      )
  }

}
