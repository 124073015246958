<section class="feature_section layout_padding" *ngIf="categorias.length > 0">
    <div class="container">
        <div class="heading_container">
            <h2>
                Categorias em destaque
            </h2>

        </div>
        <div class="row">

            <div class="col-sm-6 col-lg-3" *ngFor="let c of categorias">
                <div class="box" [routerLink]="['/categorias', c.id]">
                    <div class="img-box">
                        <img src="{{c.image_url}}" alt="">
                    </div>
                    <div class="detail-box">
                        <h5>
                            {{c.name}}
                        </h5>

                        <a [routerLink]="['/categorias', c.id]">
                            <span>
                                Produtos
                            </span>
                            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
        <div class="btn-box">
            <a [style.background]="config.cor_btn" style="border: none;" [routerLink]="['/categorias']">
                Ver todas as categorias
            </a>
        </div>
    </div>
</section>