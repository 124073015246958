import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProdutosService {
  SERVER_URL = ''

  constructor(private http: HttpClient) {
    this.SERVER_URL = environment.url
  }

  public getProdutosDestaques() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/destaques', { headers: reqHeader });
  }

  public getProdutosNovos() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/novosProdutos', { headers: reqHeader });
  }

  public getProdutosMaisVendidos() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/maisVendidos', { headers: reqHeader });
  }

  public pesquisa(pesquisa) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/pesquisa',
      { headers: reqHeader, params: { pesquisa: pesquisa } });
  }

  public getCategorias() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/categorias', { headers: reqHeader });
  }

  public geCategoriasEmDestaque() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/categoriasEmDestaque', { headers: reqHeader });
  }

  public getCarrossel() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/carrossel', { headers: reqHeader });
  }

  public getCategoria(id) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/categoria/' + id, { headers: reqHeader });
  }

  public getProdutosPorCategoria(id) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/porCategoria/' + id, { headers: reqHeader });
  }

  public getProdutosPorId(id, token) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/produtos/porId', { headers: reqHeader, 
      params: {id: id, token: token} });
  }

  public favorito(produtoId, token):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/produtos/favorito',
      { produtoId: produtoId, token: token }, { headers: reqHeader });
  }
}
