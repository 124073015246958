import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CarrinhoService } from '../services/carrinho.service';

@Component({
  selector: 'app-carrinho-body',
  templateUrl: './carrinho-body.component.html',
  styleUrls: ['./carrinho-body.component.css']
})
export class CarrinhoBodyComponent implements OnInit {
  produtos = []
  total = 0
  totalAux = 0
  cep = ''
  loader = false

  tipoFrete = 'c'
  frete: any = null
  loadding = true
  @Input() config: any = {}

  constructor(
    private storageService: LocalStorageService,
    private carrinhoService: CarrinhoService
  ) { }

  ngOnInit(): void {
    this.montarCarrinho()
  }

  montarCarrinho() {
    this.total = 0
    this.totalAux = 0
    let cart = this.storageService.getCarrinho()
    this.getItens(cart)
  }

  getItens(cart) {
    this.carrinhoService.itens(JSON.stringify(cart)).subscribe(
      data => {
        console.log(data)
        this.produtos = data
        this.addQuantidade(cart)
        this.loadding = false
      },
      err => {
        console.log(err)
        this.storageService.carrinhoClear()
        this.loadding = false
      }
    )
  }

  addQuantidade(cart) {
    for (let i = 0; i < this.produtos.length; i++) {
      cart.map((c) => {
        if (c.id == this.produtos[i].id) {
          this.produtos[i].quantidade = c.quantidade

          if (this.produtos[i].type == 'single') {
            this.total += this.produtos[i].quantidade * this.produtos[i].valor_ecommerce
          }else{
            this.total += this.produtos[i].quantidade * this.produtos[i].variacao.sell_price_inc_tax
          }
          this.totalAux = this.total
        }
      })
    }
  }

  deleteItem(produto) {
    this.storageService.deleteItem(produto)
    this.total = 0
    setTimeout(() => {
      this.montarCarrinho()
    }, 500);
  }

  valueChange(p, value) {
    let v = value.key
    try {
      v = parseInt(v)
      if (v > 0) {
        this.atualizaCarrinho(p, v)
      } else {
        this.atualizaCarrinho(p, 1)
      }
    } catch {

    }
  }

  atualizaCarrinho(produto, quantidade) {
    let cart = this.storageService.getCarrinho()
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].id == produto.id) {
        cart[i].quantidade = quantidade
      }
    }

    setTimeout(() => {
      this.storageService.setCarrinho(cart)
    }, 300);
  }

  calcular() {
    this.frete = []
    this.loader = true
    let cart = this.storageService.getCarrinho()

    this.carrinhoService.calcularFrete(this.cep, JSON.stringify(cart))
      .subscribe(
        data => {
          console.log(data)
          this.loader = false
          this.frete = data
        },
        err => {
          this.loader = false
        }
      )
  }

  selectFrete(tipo) {
    this.tipoFrete = tipo
    if (tipo == 'pac') {
      this.total = this.totalAux + parseFloat(this.frete.preco.replace(",", "."))
    } else if (tipo == 'sedex') {
      this.total = this.totalAux + parseFloat(this.frete.preco_sedex.replace(",", "."))
    } else {
      this.total = this.totalAux
    }
  }

}
