import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { CarrinhoService } from '../services/carrinho.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ClienteService } from '../services/cliente.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout-body',
  templateUrl: './checkout-body.component.html',
  styleUrls: ['./checkout-body.component.css']
})
export class CheckoutBodyComponent implements OnInit {

  @Input() config: any = {}
  produtos = []
  total = 0

  clientForm: any
  tipoFrete = 'c'
  tpDoc = 'cpf'
  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private carrinhoService: CarrinhoService,
    private clienteService: ClienteService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    this.createForm()
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.tipoFrete = params.get('tipoFrete');
      });
    this.montarCarrinho()
  }

  createForm() {
    this.clientForm = this.formBuilder.group({
      tp_doc: ['cpf', []],
      nome: ['', [Validators.required]],
      sobre_nome: ['', [Validators.required]],
      telefone: ['', [Validators.required, Validators.minLength(11)]],
      senha: ['', [Validators.required, Validators.minLength(4)]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      ie: ['', []],
      rua: ['', [Validators.required, Validators.minLength(5)]],
      numero: ['', [Validators.required, Validators.minLength(1)]],
      bairro: ['', [Validators.required, Validators.minLength(3)]],
      cidade: ['', [Validators.required, Validators.minLength(2)]],
      uf: ['', [Validators.required, Validators.minLength(2)]],
      cep: ['', [Validators.required, Validators.minLength(8)]],
      complemento: ['', []],
      email: ['', [Validators.email, Validators.required]],
      check: ['', [Validators.required]],
    });

    // this.clientForm = this.formBuilder.group({
    //   tp_doc: ['cpf', []],
    //   nome: ['Marcos', [Validators.required]],
    //   sobre_nome: ['Mello', [Validators.required]],
    //   telefone: ['43996347016', [Validators.required, Validators.minLength(11)]],
    //   senha: ['1234', [Validators.required, Validators.minLength(4)]],
    //   cpf: ['09520985980', [Validators.required, Validators.minLength(11)]],
    //   ie: ['', []],
    //   rua: ['Aldo ribas', [Validators.required, Validators.minLength(5)]],
    //   numero: ['190', [Validators.required, Validators.minLength(1)]],
    //   bairro: ['Remonta', [Validators.required, Validators.minLength(3)]],
    //   cidade: ['Jaguariaiva', [Validators.required, Validators.minLength(2)]],
    //   uf: ['PR', [Validators.required, Validators.minLength(2)]],
    //   cep: ['84200000', [Validators.required, Validators.minLength(8)]],
    //   complemento: ['', []],
    //   email: ['marcos@bueno.com', [Validators.email, Validators.required]],
    // });
  }

  selectDoc() {
    this.tpDoc = this.clientForm.value.tp_doc
  }

  montarCarrinho() {
    let cart = this.storageService.getCarrinho()
    this.getItens(cart)
  }

  getItens(cart) {
    this.carrinhoService.itens(JSON.stringify(cart)).subscribe(
      data => {
        this.produtos = data
        this.addQuantidade(cart)
      },
      err => {
      }
    )
  }

  addQuantidade(cart) {
    for (let i = 0; i < this.produtos.length; i++) {
      cart.map((c) => {
        if (c.id == this.produtos[i].id) {
          this.produtos[i].quantidade = c.quantidade

          if (this.produtos[i].type == 'single') {
            this.total += this.produtos[i].quantidade * this.produtos[i].valor_ecommerce
          } else {
            this.total += this.produtos[i].quantidade * this.produtos[i].variacao.sell_price_inc_tax
          }
        }
      })
    }
  }

  salvarCliente() {
    this.validaDocumento(this.clientForm.value.cpf, (valid) => {
      if (valid) {
        this.validDuplicado(this.clientForm.value, (dup) => {
          // if (false) {
          if (dup.length > 0 || dup == "error") {
            let msg = ""
            if (dup.length > 0) {
              dup.map((m) => {
                msg += m + "\n"
              })
            }
            swal("Erro", msg, "error")
          } else {
            this.clienteService.salvar(this.clientForm.value)
              .subscribe(
                data => {
                  this.localStorage.setCliente(data);
                  this.router.navigate(['/endereco', this.tipoFrete]);
                },
                err => {
                }
              )
          }
        })
      } else {
        swal("Erro", "Documento inválido!", "error")
      }
    })
  }

  validDuplicado(value, call) {
    let data = {
      cpf: value.cpf,
      email: value.email
    }

    this.clienteService.cadastroDuplicado(data)
      .subscribe(
        data => {
          call(data)
        },
        err => {
          call("error")
        }
      )
  }

  validaDocumento(doc, call) {

    let retorno = true
    doc = doc.replaceAll(".", "")
    doc = doc.replaceAll("-", "")
    doc = doc.replaceAll("/", "")

    if (doc.length == 11) {
      var Soma;
      var Resto;
      Soma = 0;
      let strCPF = doc
      if (strCPF == "00000000000") {
        retorno = false;
        call(retorno)
      }

      let i
      for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) retorno = false;

      Soma = 0;
      for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) retorno = false;
      call(retorno)
    } else {
      let cnpj = doc.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14)
        return false;

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999") {
        retorno = false;
        call(retorno)
      }

      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      let i
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
        retorno = false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
        retorno = false;

      call(retorno)
    }

  }

}
