import { Component } from '@angular/core';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ecommerce';
  config: any = {}
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  constructor(
    private configService: ConfigService,
  ) {
    this.getConfig()
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        console.log(data)
        if (data) {
          if (data.fav_icon != "") {
            this.favIcon.href = data.fav_url;
          } else {
            this.favIcon.href = data.logo_url;
          }
        }
      }, err => {
        console.log(err)
      }
    )
  }
}
